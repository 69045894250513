<main>
  <h1>Проверка ГЕО</h1>
  <div class="section">
    <form class="form" [formGroup]="form" (ngSubmit)="submit()">
      <div class="row form-row">
        <div class="form-group">
          <label for="dadataAddress">Введите адрес доставки</label>
          <ngx-dadata id="dadataAddress"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="dadataAddress"
                      (ngModelChange)="changeDadataAddress()"
                      [config]="config"
                      (selected)="onAddressSelected($event)"
                      [placeholder]="'Москва, ул Ленина д 1'"
          ></ngx-dadata>
        </div>
        <div class="form-group" style="margin: auto 0;text-align: center;">или</div>
        <div class="form-group">
          <label for="coords">Введите координаты</label>
          <input class="form-control"
                 formControlName="coords"
                 type="text"
                 id="coords"
                 (input)="changeCoords()"
          >
        </div>
        <div class="form-group" style="max-width: 400px;">
          <label for="productID">Выберите продукт</label>
          <select class="form-control"
                  formControlName="productID"
                  id="productID">
            <option [value]="''"></option>
            <option *ngFor="let product of productList" [value]="product.ID">{{ product.name }}</option>
          </select>
        </div>
        <div class="form-group mt-auto">
          <button type="submit"
                  class="btn btn-primary"
                  [disabled]="loading"
          >Проверить</button>
        </div>
      </div>
      <div class="alert alert-danger m-2" *ngIf="errorMessage">{{ errorMessage }}</div>
    </form>
    <div class="sticky-container" *ngIf="deliveryInfo?.availableTimeSlots?.length">
      <div class="sticky-block">
        <table class="sticky-table sticky-table-full">
          <tr>
            <th>Город</th>
            <td [colSpan]="deliveryInfo.availableTimeSlots.length">{{ deliveryInfo.mainCity }}</td>
          </tr>
          <tr>
            <th>
              Регион
              <p class="m-0">Комментарий</p>
            </th>
            <td [colSpan]="deliveryInfo.availableTimeSlots.length">
              {{ deliveryInfo.region }}
              <p class="m-0">{{ deliveryInfo.comment }}</p>
            </td>
          </tr>
          <tbody>
          <tr>
            <th>Доступные даты</th>
            <th *ngFor="let slot of deliveryInfo.availableTimeSlots">
              {{ slot.availableDate }}
            </th>
          </tr>
          <tr>
            <th>Доступное время</th>
            <td *ngFor="let slot of deliveryInfo.availableTimeSlots">
              <p class="m-0" *ngFor="let time of slot.availableFixTimeInterval">{{ time }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</main>
