<div class="row">
  <div class="m-auto" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="col-md-6 col-12" *ngIf="errorMessage">
    <div class="alert alert-danger">{{ errorMessage }}</div>
  </div>
  <div class="col-md-6 col-12 d-flex flex-column pt-2" *ngIf="!loading && !errorMessage">
    <div>
      <img [src]="partnerLogo | safeUrl" height="30" class="d-inline-block align-top mr-1" alt="">
    </div>
    <ng-container *ngIf="agreedStatusList.includes(orderDetail?.status)">
      <div>
        <h2 class="mt-3 mb-3" [innerHTML]="orderDetail.clientGreetings| safeHTML"></h2>
      </div>
      <div class="description description-dark mb-2">
<!--        <form [formGroup]="agreedForm" (ngSubmit)="toAgreed()">-->
        <form [formGroup]="clForm" (ngSubmit)="toAction('to-agreed')">
          <label for="dadataAddress" class="mt-2">Адрес</label>
<!--          <ngx-dadata id="dadataAddress" formControlName="dadataAddress" [config]="config" (selected)="onAgreedAddressSelected($event)"></ngx-dadata>-->
          <ngx-dadata id="dadataAddress" formControlName="dadataAddress" [config]="config" (selected)="onAddressSelected()"></ngx-dadata>
<!--          <div class="alert alert-danger mt-2 mb-2 p-2" *ngIf="errorMessageDadataAgreed">{{ errorMessageDadataAgreed }}</div>-->
          <label for="clientComment" class="mt-2">Комментарий</label>
          <input class="form-control mb-2"
                 type="text"
                 id="clientComment"
                 formControlName="clientComment">
          <div class="calendar">
            <ngb-datepicker #dp formControlName="deliveryDate"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [dayTemplate]="customDay"
            ></ngb-datepicker>
            <label for="interval" class="mt-2 mb-0">Выберите интервал</label>
<!--            <select class="form-control mt-1" id="interval" formControlName="interval" [class.disabled]="!intervalAgreedList?.length">-->
            <select class="form-control mt-1" id="interval" formControlName="interval" [class.disabled]="!intervalList?.length">
              <option value=""></option>
              <option *ngFor="let interval of intervalList" [value]="interval">{{ interval }}</option>
            </select>
          </div>
<!--          <div class="alert alert-danger mt-2 pt-1 pb-1" *ngIf="errorMessageAgreed">{{ errorMessageAgreed }}</div>-->
          <div class="alert alert-danger mt-2 pt-1 pb-1" *ngIf="errorActionMessage">{{ errorActionMessage }}</div>
          <button type="submit"
                  class="btn btn-primary mt-2 mb-1"
                  [disabled]="clForm.invalid || loadingAction"
          >Запланировать</button>
<!--          <button type="submit"-->
<!--                  class="btn btn-primary mt-2 mb-1"-->
<!--                  [disabled]="agreedForm.invalid || loadingAgreed"-->
<!--          >Запланировать</button>-->
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="orderDetail &&  !agreedStatusList.includes(orderDetail?.status) && !endStatusList.includes(orderDetail?.status)">
      <h4 class="mb-2">Давайте проверим параметры встречи:</h4>
      <div class="description description-dark mb-2">
        <div class="title">Доставка</div>
        <p class="text mb-2">{{ orderDetail.productName }} от {{ orderDetail.partnerName }}</p>
        <div class="title">Адрес</div>
        <p class="text mb-0">{{ orderDetail.deliveryAddress }}</p>
        <div class="title">Дата</div>
        <p class="text mb-2">{{ orderDetail.deliveryTimeStart | date : 'EEEE, d MMMM' | titlecase }} с {{ orderDetail.deliveryTimeStart | date : 'H' : '+0300' }} до {{ orderDetail.deliveryTimeFinish | date : 'H' : '+0300' }}</p>
        <p class="text mb-2" [innerHTML]="orderDetail.clientMeetRemind | safeHTML"></p>
      </div>
      <button type="button"
              *ngIf="!postponeMode"
              class="btn btn-action mt-2 mb-1"
              (click)="postponeMode = !postponeMode"
      >Изменить место и время</button>
      <div class="description description-dark mb-2" *ngIf="postponeMode">
<!--        <form [formGroup]="postponeForm" (ngSubmit)="toPostpone()">-->
        <form [formGroup]="clForm" (ngSubmit)="toAction('to-postpone')">
          <label for="dadataAddress" class="mt-2">Адрес</label>
<!--          <ngx-dadata id="dadataAddressP" formControlName="dadataAddress" [config]="config" (selected)="onPostponeAddressSelected($event)"></ngx-dadata>-->
          <ngx-dadata id="dadataAddressP" formControlName="dadataAddress" [config]="config" (selected)="onAddressSelected()"></ngx-dadata>
<!--          <div class="alert alert-danger mt-2 mb-2 p-2" *ngIf="errorMessageDadataPostpone">{{ errorMessageDadataPostpone }}</div>-->
          <label for="clientComment" class="mt-2">Комментарий</label>
          <input class="form-control mb-2"
                type="text"
                id="clientCommentP"
                formControlName="clientComment">
          <div class="calendar">
            <ngb-datepicker #dp formControlName="deliveryDate"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            [dayTemplate]="customDay"
            ></ngb-datepicker>
            <label for="interval" class="mt-2 mb-0">Выберите интервал</label>
<!--            <select class="form-control mt-2" id="intervalP" formControlName="interval" [class.disabled]="!intervalPostponeList?.length">-->
            <select class="form-control mt-2" id="intervalP" formControlName="interval" [class.disabled]="!intervalList?.length">
              <option value=""></option>
              <option *ngFor="let interval of intervalList" [value]="interval">{{ interval }}</option>
            </select>
          </div>
<!--          <div class="alert alert-danger mt-2 pt-1 pb-1" *ngIf="errorMessagePostpone">{{ errorMessagePostpone }}</div>-->
          <div class="alert alert-danger mt-2 pt-1 pb-1" *ngIf="errorActionMessage">{{ errorActionMessage }}</div>
          <div class="d-flex">
            <button type="submit"
                    class="btn btn-primary mt-2 mb-1 mr-1 w-50"
                    [disabled]="clForm.invalid || loadingAction"
            >Перенести встречу</button>
<!--            <button type="submit"-->
<!--                    class="btn btn-primary mt-2 mb-1 mr-1 w-50"-->
<!--                    [disabled]="postponeForm.invalid || loadingPostpone"-->
<!--            >Перенести встречу</button>-->
            <button type="button"
                    class="btn btn-outline-action mt-2 mb-1 ml-1 w-50"
                    (click)="postponeMode = false"
            >Отмена</button>
          </div>
        </form>
      </div>
      <ng-container *ngIf="orderDetail.status === 'PLANNED'">
        <h3 class="mt-3">К вам приедет:</h3>
        <div class="description p-0">
          <div class="d-flex">
            <div class="image-box image-box d-flex justify-content-center mr-2">
              <img [src]="agentPhoto | safeUrl" alt="Фотография агента">
            </div>
            <div>
              <p class="text m-0">ФИО Агента: <b>{{ orderDetail.agentInfo?.lastName }} {{ orderDetail.agentInfo?.firstName}}</b></p>
              <p class="text m-0">Телефон: <b>{{ orderDetail.agentInfo?.phone }}</b></p>
            </div>
          </div>
        </div>
      </ng-container>
      <h3 class="mt-3 mb-2">Как пройдёт встреча</h3>
      <div class="description">
        <p class="text m-0" [innerHTML]="orderDetail.productMeetDescription | safeHTML"></p>
      </div>
      <h3 class="mt-3 mb-3">Описание продукта</h3>
      <div class="description">
        <p class="text m-0" [innerHTML]="orderDetail.productDescription | safeHTML"></p>
      </div>
    </ng-container>
    <ng-container *ngIf="authCancelled">
      <div class="description description-dark mt-auto mb-auto">
        <p class="text mb-2">Уважаемый клиент!</p>
        <p class="text mb-0">Страница заказа не доступна.</p>
        <p class="text mb-2">Если у Вас есть вопросы, позвоните по номеру 8(800)-550-79-86</p>
        <p class="text mb-2">Финдоставка - лидер по доставке финансовых продуктов</p>
      </div>
    </ng-container>
    <ng-container *ngIf="npsStatusList.includes(orderDetail?.status) && orderDetail?.status !== 'PLANNED'">
      <div class="description description-dark mt-auto mb-auto">
        <p class="text mb-2">Уважаемый клиент!</p>
        <p class="text mb-0">Ваш заказ завершен. Поэтому страница заказа не доступна.</p>
        <p class="text mb-2">Если у Вас есть вопросы, позвоните по номеру 8(800)-550-79-86</p>
        <p class="text mb-2">Финдоставка - лидер по доставке финансовых продуктов</p>
      </div>
    </ng-container>
    <ng-container *ngIf="npsStatusList.includes(orderDetail?.status)">
      <div>
        <h2 class="mt-3 mb-3">Оцените своевременность доставки, внешний вид, знания агента</h2>
      </div>
      <div class="description description-dark mb-2">
        <label for="nps" class="mt-2">Оценка</label>
        <select class="form-control mb-2" id="nps" [(ngModel)]="nps">
          <option value=""></option>
          <option [value]="npsItem.id" *ngFor="let npsItem of npsList">{{ npsItem.title }}</option>
        </select>
<!--        <div class="alert alert-danger mt-2 mb-2 p-2" *ngIf="errorMessageDadataAgreed">{{ errorMessageDadataAgreed }}</div>-->
        <ng-container *ngIf="nps">
          <label for="npsComment" class="mt-2">Комментарий</label>
          <textarea rows="3"
                    class="form-control mb-2"
                    placeholder="Что мы можем улучшить?"
                    type="text"
                    id="npsComment"
                    [(ngModel)]="npsComment"
          ></textarea>
        </ng-container>
        <div class="alert alert-danger mt-2 pt-1 pb-1" *ngIf="errorMessageNPS">{{ errorMessageNPS }}</div>
        <div class="alert alert-success mt-2 pt-1 pb-1" *ngIf="infoMessageNPS">{{ infoMessageNPS }}</div>
        <button type="button"
                (click)="submitNPS()"
                class="btn btn-primary mt-2 mb-1"
                [disabled]="!nps || loadingNPS"
        >Оценить</button>
      </div>
    </ng-container>
  </div>
</div>

<ng-template
  #customDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <div
    class="custom-day btn-light"
    [class.custom-disabled]="!disabled && isCustomDisabled(date)"
    [class.focused]="focused"
    [class.bg-selected]="selected"
    [class.hidden]="date.month !== currentMonth"
    [class.text-muted]="!selected && (disabled || date.month !== currentMonth)"
    [class.outside]="!selected && (disabled || date.month !== currentMonth)"
  >
    {{ date.day }}
  </div>
</ng-template>
